import { t } from '@zupr/i18n'
import { aggregationKeys, filterKeys } from '@zupr/next/helpers/locations'
import { LocationsAndAggregations } from '@zupr/types/next'
import { dayString } from '@zupr/utils/datetime'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import '../../../../scss/react/filter/filters.scss'
import RouteContext from '../../../context/route'
import { useIsMobile } from '../../../context/ux'
import PaginationSort from '../../../shared/components/pagination/sort'
import Trans from '../../../shared/components/trans'
import AutocompleteFilter, {
    RemoveAutocompleteFilter,
} from '../../../shared/filters/autocomplete'
import FilterBox from '../../../shared/filters/box'
import MutltiselectFilter from '../../../shared/filters/mulitselect'
import RemoveFilter, { RemoveKeys } from '../../../shared/filters/remove'
import SwitchField from '../../../shared/form/fields/switch'
import FilterContainer from '../../components/search/filter/container'
import ViewSwitch from './components/view-switch'
import CityFilter from './filters/city'
import LocationClassifications, {
    RemoveClassifications,
} from './filters/classification'
import ShopperLocationFilter, {
    RemoveShopperLocation,
} from './filters/shopper-location'

export interface LocationsFiltersProps extends LocationsAndAggregations {
    baseUrl: string
    atMap?: boolean
    atBrand?: boolean
    viewSwitch?: boolean
}

const LocationsFilters = ({
    aggregations,
    locations,
    atMap = false,
    viewSwitch = true,
    classificationBreadcrumbs,
    filter,
    classifications,
    activeClassification,
    baseUrl,
    atBrand,
}: LocationsFiltersProps) => {
    const isMobile = useIsMobile()
    const { changeQuery } = useContext(RouteContext)
    const { query, push } = useRouter()

    const { variables } = filter

    const removeable = {
        ordering: query.ordering && t(query.ordering, 'filter'),
        type: query.type && t(query.type, 'types'),
        delivery: t('Bezorgen'),
        reservation: t('Reserveren'),
        collect: t('Afhalen'),
        open: t('Open today'),
        city: query.city,
    }

    return (
        <FilterContainer
            resultCount={locations.count}
            filterCount={filter.filterCount}
            renderFilters={
                <div className="filters">
                    {!isMobile && viewSwitch && (
                        <ViewSwitch baseUrl={baseUrl} mapView={atMap} />
                    )}

                    {filter.filterCount > 0 && (
                        <FilterBox
                            header={
                                <>
                                    <h4>{t('Gekozen filters')}</h4>
                                    <RemoveKeys
                                        keys={[
                                            'ordering',
                                            ...Object.keys(query),
                                        ]}
                                    />
                                </>
                            }
                        >
                            <RemoveClassifications
                                classifications={classificationBreadcrumbs}
                            />

                            {Object.keys(removeable)
                                .filter((key) => !!query[key])
                                .map((key) => (
                                    <RemoveFilter filterKey={key}>
                                        {removeable[key]}
                                    </RemoveFilter>
                                ))}

                            {query.brands && (
                                <RemoveAutocompleteFilter
                                    url="fo/brand"
                                    filterKey="brands"
                                />
                            )}

                            {variables[filterKeys.box] && (
                                <RemoveShopperLocation />
                            )}
                        </FilterBox>
                    )}

                    {isMobile && (
                        <FilterBox header={<></>}>
                            <PaginationSort
                                defaultOrdering="-created"
                                choices={['-created', 'title']}
                            />
                        </FilterBox>
                    )}

                    <FilterBox
                        header={
                            <>
                                <label htmlFor="opening-hours">
                                    <Trans label="Open today" />
                                </label>
                                <SwitchField
                                    field={{
                                        name: 'opening-hours',
                                        value: !!query.open,
                                        setValue: () => {
                                            push(
                                                changeQuery({
                                                    open: query.open
                                                        ? null
                                                        : dayString(),
                                                })
                                            )
                                        },
                                    }}
                                />
                            </>
                        }
                    />

                    <ShopperLocationFilter variables={variables} />

                    <MutltiselectFilter
                        label="Afhalen of bezorgen"
                        options={[
                            {
                                label: 'Laat bezorgen',
                                filterKey: 'delivery',
                                checked: {
                                    value: 'true',
                                },
                            },
                            {
                                label: 'Reserveren',
                                filterKey: 'reservation',
                                checked: {
                                    value: 'true',
                                },
                            },
                            {
                                label: 'Afhalen',
                                filterKey: 'collect',
                                checked: {
                                    value: 'true',
                                },
                            },
                        ]}
                    />

                    {isMobile && (
                        <LocationClassifications
                            classifications={classifications}
                            activeClassification={activeClassification}
                            classificationBreadcrumbs={
                                classificationBreadcrumbs
                            }
                        />
                    )}

                    <CityFilter aggregations={aggregations} />

                    {!atBrand && (
                        <AutocompleteFilter
                            url="fo/brand"
                            filterKey="brands"
                            label="Brands"
                            variables={{
                                for: 'locations',
                                ordering: 'title',
                            }}
                            options={aggregations.data?.[
                                aggregationKeys.brand
                            ]?.buckets.map((bucket) => ({
                                id: bucket.key,
                                count: bucket.doc_count,
                            }))}
                        />
                    )}
                </div>
            }
        />
    )
}

export default LocationsFilters
