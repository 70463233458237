import { Location } from '@zupr/types/fo'
import React from 'react'

import Address from './address'
import NowOpen from './hours/nowopen'

interface Props {
    opening_hours?: Location['opening_hours']
    address: Location['address']
    number: Location['number']
    zipcode: Location['zipcode']
    city: Location['city']
    is_verified?: Location['is_verified']
}

const AddressAndHours = ({
    opening_hours,
    address,
    number,
    zipcode,
    city,
    is_verified,
}: Props) => (
    <React.Fragment>
        <p className="address">
            <Address
                address={address}
                number={number}
                zipcode={zipcode}
                city={city}
            />
        </p>
        {is_verified && (
            <p className="hours">
                <NowOpen opening_hours={opening_hours} time />
            </p>
        )}
    </React.Fragment>
)

export default AddressAndHours
